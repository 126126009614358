import axios from 'axios'
const state = {}
const getters = {}
const mutations = {}
const actions = {
    postNewStudent({ commit }, newStudent) {
        return new Promise(async (resolve, reject) => {
            try {
                let form = new FormData();
                for (let key in newStudent) {
                    if (newStudent[key]) {
                        form.append(`${key}`, newStudent[key])
                    }
                }
                await axios.post("/student", form);
                resolve()
            }
            catch (err) {
                reject()
            }
        })
    },
}



export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}