import axios from 'axios'
import i18n from '@/i18n';
import {confirmDialog} from "@/helpers/sweetalert";

const state = {
  options: {},
  serverTotal: 1,
  data: null,
  url: '/document',
  postUrl: '/document/pay-installment',
  loading: false,
  headers: [
    {text: i18n.t('id'), value: 'id'},
    {text: i18n.t('date'), value: 'date'},
    {text: i18n.t('Revenue account'), value: 'account.name'},
    {text: i18n.t('hr.employee.note'), value: 'desc'},
    
    
  ],
  headersResterction: [
    {text: i18n.t('Restriction Number'), value: 'entry'},
    {text: i18n.t('date'), value: 'date'},
    {text: i18n.t('student name'), value: 'student'},
    {text: i18n.t('route name'), value: 'route'},
    {text: i18n.t('Value'), value: 'amount'},
  ],
  payment: null,
  paymentHeaders: [
    {text: i18n.t('student name'), value: 'account'},
    {text: i18n.t('class'), value: 'grade'},
    {text: i18n.t('educitional class'), value: 'edu_class'},
    {text: i18n.t('due-date'), value: 'date'},
    {text: i18n.t('hr.loan.trans-value'), value: 'amount'},
    {text: i18n.t('previous payments'), value: 'payment'},
    {text: i18n.t('hr.payment.amount_payed'), value: 'amount_payed'},
  ]

}
const getters = {
  data(state) {
    return state.data
  },
  isThereCalculation(state) {
    return state.payment
  },
  paymentData(state) {
    return (state.payment?.data ?? []).map((el)=>({
        ...el,
        salary: el.amount,
    }))
  },
  headers(state) {
    return [...state.headers, {text: i18n.t('settings'), value: 'actions'}]
  },
  headersResterction(state) {
    return [...state.headersResterction, {text: i18n.t('settings'), value: 'actions'}]
  },
  paymentHeaders(state) {
    return [...state.paymentHeaders]
  }
}
const mutations = {}
const actions = {
  async fetch({commit}, queryParams) {
    try {
      state.loading = true;
      let endpoint = '';
      let params = {};
      if(queryParams.id){
        endpoint = `${state.url}/${queryParams.id}`
      } else {
        endpoint = state.url;
        params = {
          type: 'ROUTE',
          page: state.options?.page ?? 1,
          perPage: state.options?.itemsPerPage ?? 10,
          per_page: state.options?.itemsPerPage ?? 10,
          account_id: queryParams.account_id,
          from: queryParams.form,
          to: queryParams.to,
          desc: queryParams.desc,
          route_id: queryParams.route_id,
        };
      }
      

      const response = await axios.get(endpoint, {
        params,
      });
      state.data = (response.data.data ?? []);

      
      // .map(el => ({
      //   ...el, ...((el.details ?? []).reduce((prev, curr) => {
      //     prev[curr.key] = curr.value
      //     return prev
      //   }, {}))
      // }))


      state.serverTotal = response.data.meta.total
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async exportPayments({dispatch}, queryParams) {
    const excel = await axios.get('hr/document/pay/export', {
      params: {
        ids: state.paymentData.map(el => el.id),
      },
      responseType: 'blob'
    });
    saveAs(excel.data)
    dispatch('fetch')
  },
  async restorePayment({commit, dispatch}, form) {
    try {
      state.loading = true
      let params = {
        ...form
      };
      const response = await axios.get('/inventories/installment/index', {
        params
      });
      state.payment = response.data
      console.log(state.payment);
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  setPayment({}, payment) {
    state.payment = payment
  },
  async submitPaymentsAction({commit, dispatch}, data) {
    try {
      state.loading = true
      let params = {};
      const response = await axios.post(`${state.postUrl}`, data);
      state.payment = response.data
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async delete({commit, state, dispatch}, id) {
    try {
      state.loading = true
      if (await confirmDialog()) {
        await axios.delete(`${state.url}/${id}`);
        dispatch('fetch')
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
