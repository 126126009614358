<template>
  <div >
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },
  created() {
    // this.$router.push("/login");

    if (!localStorage.getItem("access_token")) {
      this.$router.push("/login");
    } else {
      this.$store.dispatch("autoLogIn");
    }
  },
};
</script>

<style src="./globalCss/global.css"></style>
